<template>
  <div class="tool-nav">
    header功能菜单
  </div>
</template>

<script>
export default {
  name: 'ToolNav',
  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
</style>