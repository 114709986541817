import request from './request'
const version = process.env.VUE_APP_VERSION

export function getComps () {
  return request.getJSON('/data/screen.json')
}

export function getDemo () {
  return request.getJSON('/data/demo.json')
}

export function getNavi () {
  return request.getJSON('/data/navi.json')
}

export function getCompsType () {
  return request.getJSON('/data/compsType.json')
}

export function getManageNavi () {
  return request.getJSON('/data/manage.json')
}

const MAP = '/data/map/';
export function getMapByType (type) {
  switch(type) {
    case 1:
      return request.getJSON(MAP + 'beijing.json')
    case 2:
      return request.getJSON(MAP + 'tianjin.json')
    case 3:
        return request.getJSON(MAP + 'shanghai.json')
    case 4:
      return request.getJSON(MAP + 'chongqing.json')
    case 5:
      return request.getJSON(MAP + 'hebei.json')
    case 6:
      return request.getJSON(MAP + 'henan.json')
    case 7:
      return request.getJSON(MAP + 'yunnan.json')
    case 8:
        return request.getJSON(MAP + 'liaoning.json')
    case 9:
      return request.getJSON(MAP + 'heilongjiang.json')
    case 10:
        return request.getJSON(MAP + 'hunan.json')
    case 11:
      return request.getJSON(MAP + 'anhui.json')
    case 12:
      return request.getJSON(MAP + 'shandong.json')
    case 13:
      return request.getJSON(MAP + 'xinjiang.json')
    case 14:
      return request.getJSON(MAP + 'jiangsu.json')
    case 15:
      return request.getJSON(MAP + 'zhejiang.json')
    case 16:
      return request.getJSON(MAP + 'jiangxi.json')
    case 17:
      return request.getJSON(MAP + 'hubei.json')
    case 18:
        return request.getJSON(MAP + 'guangxi.json')
    case 19:
      return request.getJSON(MAP + 'gansu.json')
    case 20:
      return request.getJSON(MAP + 'shan_xi.json')
    case 21:
      return request.getJSON(MAP + 'neimenggu.json')
    case 22:
      return request.getJSON(MAP + 'shanxi.json')
    case 23:
        return request.getJSON(MAP + 'jilin.json')
    case 24:
      return request.getJSON(MAP + 'fujian.json')
    case 25:
        return request.getJSON(MAP + 'guizhou.json')
    case 26:
      return request.getJSON(MAP + 'guangdong.json')
    case 27:
      return request.getJSON(MAP + 'qinghai.json')
    case 28:
      return request.getJSON(MAP + 'xizang.json')
    case 29:
      return request.getJSON(MAP + 'sichuan.json')
    case 30:
      return request.getJSON(MAP + 'ningxia.json')
    case 31:
      return request.getJSON(MAP + 'hainan.json')
    case 32:
      return request.getJSON(MAP + 'taiwan.json')
    case 33:
      return request.getJSON(MAP + 'xianggang.json')
    case 34:
      return request.getJSON(MAP + 'aomen.json')
    default:
      return request.getJSON(MAP + 'china.json')
  }
}

const BORDER = '/data/map/border/';
export function getMapBorderByType (type) {
  switch(type) {
    case 1:
      return request.getJSON(BORDER + 'beijing.json')
    case 2:
      return request.getJSON(BORDER + 'tianjin.json')
    case 3:
        return request.getJSON(BORDER + 'shanghai.json')
    case 4:
      return request.getJSON(BORDER + 'chongqing.json')
    case 5:
      return request.getJSON(BORDER + 'hebei.json')
    case 6:
      return request.getJSON(BORDER + 'henan.json')
    case 7:
      return request.getJSON(BORDER + 'yunnan.json')
    case 8:
        return request.getJSON(BORDER + 'liaoning.json')
    case 9:
      return request.getJSON(BORDER + 'heilongjiang.json')
    case 10:
        return request.getJSON(BORDER + 'hunan.json')
    case 11:
      return request.getJSON(BORDER + 'anhui.json')
    case 12:
      return request.getJSON(BORDER + 'shandong.json')
    case 13:
      return request.getJSON(BORDER + 'xinjiang.json')
    case 14:
      return request.getJSON(BORDER + 'jiangsu.json')
    case 15:
      return request.getJSON(BORDER + 'zhejiang.json')
    case 16:
      return request.getJSON(BORDER + 'jiangxi.json')
    case 17:
      return request.getJSON(BORDER + 'hubei.json')
    case 18:
        return request.getJSON(BORDER + 'guangxi.json')
    case 19:
      return request.getJSON(BORDER + 'gansu.json')
    case 20:
      return request.getJSON(BORDER + 'shan_xi.json')
    case 21:
      return request.getJSON(BORDER + 'neimenggu.json')
    case 22:
      return request.getJSON(BORDER + 'shanxi.json')
    case 23:
        return request.getJSON(BORDER + 'jilin.json')
    case 24:
      return request.getJSON(BORDER + 'fujian.json')
    case 25:
        return request.getJSON(BORDER + 'guizhou.json')
    case 26:
      return request.getJSON(BORDER + 'guangdong.json')
    case 27:
      return request.getJSON(BORDER + 'qinghai.json')
    case 28:
      return request.getJSON(BORDER + 'xizang.json')
    case 29:
      return request.getJSON(BORDER + 'sichuan.json')
    case 30:
      return request.getJSON(BORDER + 'ningxia.json')
    case 31:
      return request.getJSON(BORDER + 'hainan.json')
    case 32:
      return request.getJSON(BORDER + 'taiwan.json')
    case 33:
      return request.getJSON(BORDER + 'xianggang.json')
    case 34:
      return request.getJSON(BORDER + 'aomen.json')
    default:
      return request.getJSON(BORDER + 'china1.json')
  }
}

export function getHomeVersion () {
  return request.getJSON('/data/homeVersion.json?v=' + process.env.VUE_APP_VERSION);
}