<template>
  <div>
    <frame-header>
      <template #header-tool>
        <tool-nav></tool-nav>
      </template>
    </frame-header>
    <frame>
      <template #left>
        <navi ref="navi"></navi>
      </template>
      <template #center>
        <router-view v-slot="{ Component }">
          <keep-alive v-if="$route.meta.keepAlive">
            <component :is="Component" @changeNavi="changeNavi()" @setSpOrPrj="setSpOrPrj" @setCommonParams = "setCommonParams"/>
          </keep-alive>
          <component :is="Component" @changeNavi="changeNavi()" @setSpOrPrj="setSpOrPrj" @setCommonParams = "setCommonParams" v-if="!$route.meta.keepAlive"/>
          <!--  :key="$route.fullpath"<component :is="Component" @changeNavi="changeNavi()" v-if="!$route.meta.keepAlive"/> -->
        </router-view>
      </template>
    </frame>
  </div> 
</template>

<script>
import FrameHeader from 'components/content/platform/header/Header'
import Frame from 'components/content/platform/frame/Frame'
import Navi from 'components/content/platform/navi/Navi'
import ToolNav from 'components/content/tools/ToolNav'
import { ref, onMounted, onUnmounted, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useMutataions } from "components/util/mapMutations";
import { useActions } from "components/util/mapActions";

export default {
  name: 'Main',
  components: {
    FrameHeader,
    Frame,
    Navi,
    ToolNav
  },
  setup() {
    const store = useStore();
    let prjModelList = ref(store.state.Preview.prjModelList);
    const axios = getCurrentInstance().appContext.config.globalProperties.axios;
    const storeActions = useActions("Global", ["getGlobalValues","getPageValues"]);
    const navi = ref(null);
    const mutation = useMutataions("Global", ["setSpOrPrj"]);
    
    const dealModelApi = () => {

    };

    const changeNavi = () => {
      navi.value.getNaviFn();
    };

    const setSpOrPrj = (data) => {
      mutation.setSpOrPrj(data);
    };

    const setCommonParams = async (callback) => {
      await storeActions.getGlobalValues();
      await storeActions.getPageValues();
      if(callback)
        callback();
    };


    onMounted(() => {
      
    })

    onUnmounted(() => {

    })

    return {
      navi,
      changeNavi,
      setSpOrPrj,
      setCommonParams
    };
  },
}
</script>

<style lang="scss" scoped>
</style>