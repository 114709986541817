<template>
  <div class="navi" :class="{ 'navi-open': isOpen }">
    <div class="navi-toggle" :class="{ 'navi-open': isOpen }" @click="naviToggle()"></div>
    <el-scrollbar>
      <div class="navi-title">菜单</div>
      <div class="navi-group" v-for="(p, pi) in navi" :key="pi">
        <div class="navi-parent" :class="{ 'active': getCurrentNavi(p) }" @click="naviChange(p)">
          <i :class="p.icon"></i>
          <span>{{ p.name }}</span>
        </div>
        <div class="navi-children" v-if="p.children">
          <div class="navi-child" v-for="(c, ci) in p.children" :key="ci" :class="{ 'active': getCurrentNavi(c) }"
            @click="naviChange(c)">{{ c.name }}</div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { getNavi } from 'network/getData'
import _ from 'underscore'
export default {
  name: "Navi",
  data () {
    return {
      navi: "",
      currentNavi: "",
      isOpen: true
    }
  },
  mounted () {
    this.getNaviFn();
  },
  methods: {
    getNaviObj () {
      return this;
    },
    getNaviFn () {
      let self = this;
      getNavi().then((res) => {
        let spOrPrj = self.$store.state.Global.spOrPrj;
        if (!spOrPrj) {
          self.$api.sp.getCurrentModuleType().then((data) => {
            self.navi = _.filter(res.list, (v) => {
              return !v.meta || v.meta.type == data;
            });
          })
        } else {
          self.navi = _.filter(res.list, (v) => {
            return !v.meta || v.meta.type == spOrPrj;
          });
        }
      })
      self.currentNavi = self.$route.path;
    },
    naviChange (item) {
      if (!item.path) return
      this.currentNavi = item.path;
      if (item.id && item.id == 'returnBack')
        this.$router.go(-1);
      else
        this.$router.push(item.path);
    },
    getCurrentNavi (item) {
      if (item.path == 'undefined') return;
      if (this.currentNavi.indexOf(item.path) != -1) return "active"
    },
    naviToggle () {
      this.isOpen = !this.isOpen;
    }
  },
  watch: {
    '$route.path' (val, oldVal) {
      if (val == oldVal) return
      this.currentNavi = val == 'undefined' ? this.$router.path : val;
    }
  }
}
</script>

<style lang="scss" scoped>
$navi-width: 128px;

.navi {
  width: 0;
  height: calc(100vh - 60px);
  background: rgba(255, 255, 255, 0.04);
  transition: all .1s linear;

  &.navi-open {
    width: $navi-width;

    .navi-toggle {
      width: 30px;
      height: 30px;
      margin: 10px 10px -40px 0;
      background: transparent;
      border-radius: 50%;
    }
  }

  .navi-toggle {
    width: 15px;
    height: 40px;
    position: relative;
    z-index: 105;
    float: right;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px -15px -80px 0;
    background: rgba(70, 75, 80, 0.6);
    backdrop-filter: blur(4px);
    border-radius: 0 4px 4px 0;
    transition: all .1s linear;

    &:hover {
      background: rgba(85, 95, 105, 0.6);

      &:before {
        border-color: rgba(255, 255, 255, .4);
      }
    }

    &.navi-open {
      &:before {
        transform: rotate(-135deg) translateX(-1px) translateY(1px);
      }
    }

    &::before {
      content: "";
      width: 10px;
      height: 10px;
      display: block;
      border-top: 2px solid rgba(255, 255, 255, .2);
      border-right: 2px solid rgba(255, 255, 255, .2);
      transform: rotate(45deg) translateX(-2px) translateY(2px);
      transition: all .1s linear;
    }

  }

  .navi-title {
    width: $navi-width;
    padding: 12px 12px 0;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
  }

  .navi-group {
    width: $navi-width;
    float: left;
    margin-top: 12px;

    .navi-parent {
      width: 100%;
      height: 32px;
      float: left;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      transition: all 0.1s linear;
      color: rgba(255, 255, 255, 0.8);

      &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: #fff;
      }

      &.active {
        background: rgba(30, 170, 255, 1);
        color: #fff;
      }

      &.nocur {
        cursor: default;

        &:hover {
          background: transparent;
          color: rgba(255, 255, 255, 0.8);
        }
      }

      i {
        width: 20px;
        height: 20px;
        font-style: normal;
        color: inherit;
        opacity: 0.5;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 8px 0 12px;
        font-size: 16px;
      }

      span {
        width: 1%;
        flex: 1;
        font-size: 16px;
        font-weight: bold;
        color: inherit;
      }

      p {
        width: 20px;
        height: 20px;
        margin: 0 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
          content: "";
          width: 9px;
          height: 9px;
          border-right: 2px solid rgba(255, 255, 255, 0.2);
          border-bottom: 2px solid rgba(255, 255, 255, 0.2);
          display: block;
          transform: rotate(45deg);
          margin: -4px 0 0;
        }
      }
    }

    .navi-children {
      width: 100%;
      float: left;

      .navi-child {
        width: 100%;
        float: left;
        padding-left: 40px;
        line-height: 28px;
        font-size: 14px;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.6);
        transition: all 0.1s linear;

        &:hover {
          background: rgba(255, 255, 255, 0.1);
          color: rgba(255, 255, 255, 0.8);
        }

        &.active {
          background: rgba(30, 170, 255, 1);
          color: #fff;
        }
      }
    }
  }
}
</style>